
export default {
    props: {
        title: {
            type: String,
            default: null
        },
        summary: {
            type: String,
            default: null
        },
        image: {
            type: [Object, Boolean],
            default: null
        },
        icon: {
            type: String,
            default: null
        },
        to: {
            type: [Object, String],
            default: null
        }
    }
};
