import { render, staticRenderFns } from "./ServiceCards.vue?vue&type=template&id=335df578"
import script from "./ServiceCards.vue?vue&type=script&lang=js"
export * from "./ServiceCards.vue?vue&type=script&lang=js"
import style0 from "./ServiceCards.less?vue&type=style&index=0&prod&lang=less&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ServiceCard: require('/vercel/path0/components/ServiceCard/ServiceCard.vue').default})
