
export default {
    props: {
        services: {
            type: Array,
            default: null
        },
        description: {
            type: String,
            default: null
        }
    },

    methods: {
        getImage(service) {
            if (!service) {
                return;
            }

            if (service.featuredImages && service.featuredImages.length > 0) {
                return service.featuredImages[0];
            }

            return false;
        }
    }
};
