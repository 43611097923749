import { render, staticRenderFns } from "./FlexibleSectionServices.vue?vue&type=template&id=4f53067e"
import script from "./FlexibleSectionServices.vue?vue&type=script&lang=js"
export * from "./FlexibleSectionServices.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ServiceCards: require('/vercel/path0/components/ServiceCards/ServiceCards.vue').default,BaseSection: require('/vercel/path0/components/Base/Section/BaseSection.vue').default})
